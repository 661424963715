import Logo from "../atoms/Logo";
import "./AppHeader.scss";

const AppHeader = () => {
    return (
        <div>
            <Logo />
        </div>
    );
};

export default AppHeader;
